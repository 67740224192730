/***********************
 * * Also in gatsby-node.mjs
 */

const getSlug = (page) => {
  let slug;
  // PAGES
  if (page._type === "pages") {
    slug = page.addParentPagePrefix
      ? `/${page.parentPageSlug}/${page.slug.current}`
      : `/${page.slug.current}`;
  } else if (page._type === "archivePage") {
    slug = page.addParentPagePrefix
      ? `/${page.parentPageSlug}/${page.slug.current}`
      : `/${page.slug.current}`;

    // LOCATION PAGES
  } else if (page._type === "countryPage") {
    const continent =
      page.isInEurope === "europe" ? "europe" : "further-afield";
    slug = `/destinations/${continent}/${page.slug.current}`;
  } else if (page._type === "cityPage") {
    const continent =
      page.country?.isInEurope === "europe" ? "europe" : "further-afield";
    const country = page.country?.slug.current;
    slug = `/destinations/${continent}/${country}/${page.slug.current}`;
  } else if (page._type === "regionPage") {
    const continent =
      page.country.isInEurope === "europe" ? "europe" : "further-afield";
    const country = page.country.slug.current;
    slug = `/destinations/${continent}/${country}/${page.slug.current}`;

    // PRODUCTS
  } else if (page._type === "hotelBreak") {
    slug = `/hotel/${page.slug.current}`;
  } else if (
    page._type === "tailorMadeHoliday" ||
    page._type === "culturalTour" ||
    page._type === "opera" ||
    page._type === "railJourney" ||
    page._type === "riverCruise"
  ) {
    slug = `/holidays/${page.slug.current}`;

    // CPTS
  } else if (page._type === "blog") {
    slug = `/about/news/${page.slug.current}`;
  } else if (page._type === "attraction") {
    slug = `/attraction/${page.slug.current}`;
  } else if (page._type === "review") {
    slug = `/reviews-and-testimonials/staff-reviews/${page.slug.current}`;
  } else if (page._type === "testimonial") {
    slug = `/reviews-and-testimonials/traveller-feedback/${page.slug.current}`;

    // PEOPLE
  } else if (page._type === "expert") {
    slug = `/about/our-expert-team/${page.slug.current}`;
  } else if (page._type === "tourLeader") {
    slug = `/about/kirker-tour-leaders/${page.slug.current}`;
  } else if (page._type === "artist") {
    slug = `/about/kirker-artists/${page.slug.current}`;
  } else {
    slug = `/${page.slug.current}`;
  }
  return slug;
};

export { getSlug };
